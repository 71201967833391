/**
* Template Name: QuickStart
* Template URL: https://bootstrapmade.com/quickstart-bootstrap-startup-website-template/
* Updated: May 04 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */
:root {
  --default-font: 'Outfit';
  --heading-font: "Outfit", sans-serif;
  --nav-font: "Outfit", sans-serif;
}

/* Global Colors */
:root {
  --background-color: #ffffff;
  --background-color-rgb: 255, 255, 255;
  --default-color: #3d4348;
  --default-color-rgb: 61, 67, 72;
  --heading-color: #3e5055;
  --heading-color-rgb: 62, 80, 85;
  --accent-color: #604f71;
  --accent-color-rgb: 128, 112, 148;
  --contrast-color: #ffffff;
  --contrast-color-rgb: 255, 255, 255;
}


/* Nav Menu Colors */
:root {
  --nav-color: #313336;
  --nav-hover-color: #807094;
  --nav-dropdown-color: #313336;
  --nav-dropdown-hover-color: #77b6ca;
  --nav-dropdown-background-color: #ffffff;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: rgba(var(--accent-color-rgb), 0.75);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}




/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.tagline-section {
  background-color: #ffffff; /* Light background color for contrast */
  text-align: left;
  padding: 8rem 0;
  margin-left: 4rem;
  width: 50%;
  margin-top: 30px;
}
.tagline-section h1 {
  color: #604f71; /* Dark color for the text */
  font-family: 'Inter Tight', sans-serif;
  font-size: 5rem; /* Adjust size as needed */
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
}
.header {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 10px 0;
  transition: all 0.5s;
  z-index: 997;
}

.header .logo {
  line-height: 1;
}

.header .logo img {
  max-height: 36px;
  margin-right: 8px;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
  color: var(--heading-color);
}

@media (max-width: 480px) {
  .header {
    background-color: #ffffff;
  }

  .header .logo img {
    max-height: 24px;
  }

  .header .logo h1 {
    font-size: 24px;
  }

  .tagline-section{
    width: 80%;
    margin-left: 1rem;
  }

  .tagline-section h1{
    font-size: 4rem;
  }
}

.mobile-nav-toggle {
  margin-left: 10px;
  font-size: 24px;
  cursor: pointer;
}

.header .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .btn-getstarted {
  margin-right: 10px;
}

@media (max-width:1024px) {
  .header {
    background-color: #ffffff;
  }
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-size: 14px;
  padding: 8px 25px;
  margin: 0 0 0 30px;
  border-radius: 50px;
  transition: 0.3s;
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
  color: var(--contrast-color);
  background: rgba(var(--accent-color-rgb), 0.85);
}

@media (max-width: 1200px) {
  .header .logo {
    order: 1;
  }

  .header .btn-getstarted {
    order: 2;
    margin: 0 15px 0 0;
    padding: 6px 15px;
  }

  .header .navmenu {
    order: 3;
  }
}

@media (max-width:480px) {
  .header .btn-getstarted {
    font-size: 12px;
  }
}

.scrolled .header {
  box-shadow: 0px 0 18px rgba(var(--default-color-rgb), 0.15);
}

@media (min-width: 1200px) {
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navmenu li {
    position: relative;
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-color);
    padding: 18px 15px;
    font-size: 16px;
    font-family: var(--nav-font);
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  .navmenu li:last-child a {
    padding-right: 0;
  }

  .navmenu li:hover>a,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-hover-color);
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 0px 0px 30px rgba(var(--default-color-rgb), 0.15);
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover,
  .navmenu .dropdown ul .active:hover,
  .navmenu .dropdown ul li:hover>a {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }

  .navmenu .megamenu {
    position: static;
  }

  .navmenu .megamenu ul {
    margin: 0;
    padding: 10px;
    background: var(--nav-dropdown-background-color);
    box-shadow: 0px 0px 20px rgba(var(--default-color-rgb), 0.1);
    position: absolute;
    top: 130%;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    display: flex;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
  }

  .navmenu .megamenu ul li {
    flex: 1;
  }

  .header {
    transition: background-color 0.3s ease;
  }

  .header-scrolled {
    background-color: #ffffff;
    /* Change to your desired color */
  }

  .navmenu .megamenu ul li a,
  .navmenu .megamenu ul li:hover>a {
    padding: 10px 20px;
    font-size: 15px;
    color: var(--nav-dropdown-color);
  }

  .navmenu .megamenu ul li a:hover,
  .navmenu .megamenu ul li .active,
  .navmenu .megamenu ul li .active:hover {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .megamenu:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
}

/* Mobile Navigation */
@media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    display: none;
    position: absolute;
    inset: 60px 20px 20px 20px;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-dropdown-background-color);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
    box-shadow: 0px 0px 30px rgba(var(--default-color-rgb), 0.1);
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: rgba(var(--accent-color-rgb), 0.1);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid rgba(var(--default-color-rgb), 0.1);
    transition: all 0.5s ease-in-out;
    box-shadow: none;
  }

  .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown>.dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    font-size: 32px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu>ul {
    display: block;
    height: 80%;
  }
}


/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
/* .header {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 10px 0;
  transition: all 0.5s;
  z-index: 997;
}

.header .logo {
  line-height: 1;
}

.header .logo img {
  max-height: 36px;
  margin-right: 8px;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
  color: var(--heading-color);
}


@media (max-width: 480px) {
  .header {
    background-color: #ffffff;
  }

  .header .logo img {
    max-height: 24px;
  }

  .header .logo h1 {
    font-size: 24px;
  }

}

.mobile-nav-toggle {
  margin-left: 10px;
  font-size: 24px;
  cursor: pointer;
}

.header .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .btn-getstarted {
  margin-right: 10px;
}


@media (max-width:1024px) {
  .header {
    background-color: #ffffff;
  }
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-size: 14px;
  padding: 8px 25px;
  margin: 0 0 0 30px;
  border-radius: 50px;
  transition: 0.3s;
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
  color: var(--contrast-color);
  background: rgba(var(--accent-color-rgb), 0.85);
}

@media (max-width: 1200px) {
  .header .logo {
    order: 1;
  }

  .header .btn-getstarted {
    order: 2;
    margin: 0 15px 0 0;
    padding: 6px 15px;

  }

  .header .navmenu {
    order: 3;
  }
}

@media (max-width:480px) {
  .header .btn-getstarted {
    font-size: 12px;
  }
}

.scrolled .header {
  box-shadow: 0px 0 18px rgba(var(--default-color-rgb), 0.15);

} */

/* Index Page Header
------------------------------*/
/* .index-page .header {
  --background-color: rgba(255, 255, 255, 0);
} */

/* Index Page Header on Scroll
------------------------------*/
.index-page.scrolled .header {
  --background-color: #ffffff;
}

/* ProjectDetails.css */


/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
/* @media (min-width: 1200px) {
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navmenu li {
    position: relative;
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-color);
    padding: 18px 15px;
    font-size: 16px;
    font-family: var(--nav-font);
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  .navmenu li:last-child a {
    padding-right: 0;
  }

  .navmenu li:hover>a,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-hover-color);
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 0px 0px 30px rgba(var(--default-color-rgb), 0.15);
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover,
  .navmenu .dropdown ul .active:hover,
  .navmenu .dropdown ul li:hover>a {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }

  .navmenu .megamenu {
    position: static;
  }

  .navmenu .megamenu ul {
    margin: 0;
    padding: 10px;
    background: var(--nav-dropdown-background-color);
    box-shadow: 0px 0px 20px rgba(var(--default-color-rgb), 0.1);
    position: absolute;
    top: 130%;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    display: flex;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
  }

  .navmenu .megamenu ul li {
    flex: 1;
  }

  .header {
    transition: background-color 0.3s ease;
  }

  .header-scrolled {
    background-color: #ffffff;
    /* Change to your desired color */
  /* } */

  /* .navmenu .megamenu ul li a,
  .navmenu .megamenu ul li:hover>a {
    padding: 10px 20px;
    font-size: 15px;
    color: var(--nav-dropdown-color);
  }

  .navmenu .megamenu ul li a:hover,
  .navmenu .megamenu ul li .active,
  .navmenu .megamenu ul li .active:hover {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .megamenu:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
} */


/* Mobile Navigation */
/* @media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    display: none;
    position: absolute;
    inset: 60px 20px 20px 20px;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-dropdown-background-color);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
    box-shadow: 0px 0px 30px rgba(var(--default-color-rgb), 0.1);
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: rgba(var(--accent-color-rgb), 0.1);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid rgba(var(--default-color-rgb), 0.1);
    transition: all 0.5s ease-in-out;
    box-shadow: none;
  }

  .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown>.dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    font-size: 32px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu>ul {
    display: block;
    height: 80%;
  }
} */ 

.mute-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: none;
  /* Initially hide the button */
}

.full-width-video:hover .mute-button {
  display: block;
  /* Show the button on hover */
}

.mute-button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.full-width-video {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: 40px;
  margin-bottom: 40px;
}

.video-fluid {
  width: 100%;
  height: auto;
}


/*--------------------------------------------------------------
# Global Footer
--------------------------------------------------------------*/
.footer {
  --background-color: rgba(var(--accent-color-rgb), .06);
  color: var(--default-color);
  background-color: var(--background-color);
  font-size: 14px;
  padding-bottom: 50px;
  position: relative;
}

.footer .footer-top {
  padding-top: 50px;
}

.footer .footer-about .logo {
  line-height: 1;
  margin-bottom: 25px;
}

.footer .footer-about .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-about .logo span {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: var(--heading-font);
  color: var(--heading-color);
}

.footer .footer-about p {
  font-size: 14px;
  font-family: var(--heading-font);
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(var(--default-color-rgb), 0.5);
  font-size: 16px;
  color: rgba(var(--default-color-rgb), 0.7);
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: var(--accent-color);
  border-color: var(--accent-color);
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  padding-right: 2px;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: rgba(var(--default-color-rgb), 0.7);
  display: inline-block;
  line-height: 1;
}

.footer .footer-links ul a:hover {
  color: var(--accent-color);
}

.footer .footer-contact p {
  margin-bottom: 5px;
}

.footer .footer-newsletter .newsletter-form {
  margin-top: 30px;
  padding: 6px 8px;
  position: relative;
  border-radius: 50px;
  border: 1px solid rgba(var(--accent-color-rgb), 0.3);
  display: flex;
  background-color: var(--contrast-color);
  transition: 0.3s;
}

.footer .footer-newsletter .newsletter-form:focus-within {
  border-color: var(--accent-color);
}

.footer .footer-newsletter .newsletter-form input[type=email] {
  border: 0;
  padding: 4px 10px;
  width: 100%;
  background-color: var(--contrsast-color);
  color: var(--default-color);
}

.footer .footer-newsletter .newsletter-form input[type=email]:focus-visible {
  outline: none;
}

.footer .footer-newsletter .newsletter-form input[type=submit] {
  border: 0;
  font-size: 16px;
  padding: 0 20px;
  margin: -7px -9px -7px 0;
  background: var(--accent-color);
  color: var(--contrast-color);
  transition: 0.3s;
  border-radius: 50px;
}

.footer .footer-newsletter .newsletter-form input[type=submit]:hover {
  background: rgba(var(--accent-color-rgb), 0.8);
}

.footer .footer-newsletter .error-message {
  display: none;
  background: #df1529;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  margin-top: 10px;
  font-weight: 600;
  border-radius: 4px;
}

.footer .footer-newsletter .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
  font-weight: 600;
  border-radius: 4px;
}

.footer .footer-newsletter .loading {
  display: none;
  background: var(--background-color);
  text-align: center;
  padding: 15px;
  margin-top: 10px;
}

.footer .footer-newsletter .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--accent-color);
  border-top-color: var(--background-color);
  animation: subscription-loading 1s linear infinite;
}

@keyframes subscription-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.footer .copyright {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid rgba(var(--default-color-rgb), 0.1);
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .credits {
  margin-top: 6px;
  font-size: 13px;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background-color: var(--background-color);
  transition: all 0.6s ease-out;
  width: 100%;
  height: 100vh;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  border: 4px solid var(--accent-color);
  border-radius: 50%;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
  animation-delay: -0.5s;
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Scroll Top Button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: -15px;
  z-index: 99999;
  background-color: var(--accent-color);
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--contrast-color);
  line-height: 0;
}

.scroll-top:hover {
  background-color: rgba(var(--accent-color-rgb), 0.8);
  color: var(--contrast-color);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Global Page Titles & Breadcrumbs
--------------------------------------------------------------*/
.page-title {
  --background-color: rgba(var(--default-color-rgb), .04);
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 25px 0;
  position: relative;
}

.page-title h1 {
  font-size: 24px;
  font-weight: 700;
}

.page-title .breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.page-title .breadcrumbs ol li+li {
  padding-left: 10px;
}

.page-title .breadcrumbs ol li+li::before {
  content: "/";
  display: inline-block;
  padding-right: 10px;
  color: rgba(var(--default-color-rgb), 0.3);
}


/* project section */
.project {
  margin-top: 50px;
  overflow: hidden;
}

.team {
  --background-color: rgba(var(--accent-color-rgb), .03);
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Decrease the gap */
  justify-content: center;
  margin-top: -10px;
}

.flip-card {
  background-color: transparent;
  width: 320px; /* Increase the width */
  height: 420px; /* Optional: Increase height slightly */
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
}

.flip-card-front {
  background-color: #fff;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}

.flip-card-front img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
}

.flip-card-back {
  background-color: #807094;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}

.team-name {
  color: white;
}

@media (min-width: 1025px) {
  /* .card-container {
    justify-content: space-between;
  } */

  .flip-card {
    width: 25%; /* Increase the width for larger screens */
    height: 420px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .card-container {
    justify-content: space-between;
  }

  .flip-card {
    width: 30%; /* Increase the width for tablets */
    height: 420px;
  }
}

@media (max-width: 768px) {
  .flip-card {
    width: 270px; /* Slightly increase the width for smaller screens */
    height: 370px;
  }

  .flip-card-front img {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 480px) {
  .flip-card {
    width: 320px; /* Increase width for mobile screens */
    height: 320px;
  }

  .flip-card-front img {
    width: 100px;
    height: 100px;
  }
}


/* Reduce the gap between the cards in both leadership and team sections */
.card-container.leadership, 
.card-container.team {
    display: flex;
    justify-content: center;
    gap: 20px; /* Adjust this value to decrease the gap */
}

/* Increase the width of the flip cards in both leadership and team sections */
.flip-card {
    width: 350px; /* Increase the width as needed */
}

/* Optional: Ensure the cards are responsive in both sections */
@media (max-width: 768px) {
    .flip-card {
        width: 100%; /* Make cards full width on smaller screens */
    }

    .card-container.leadership,
    .card-container.team {
        flex-direction: column;
        align-items: center;
    }
}


/*--------------------------------------------------------------
# Global Sections
--------------------------------------------------------------*/
section,
.section {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 30px 0;
  scroll-margin-top: 100px;
  overflow: clip;
}

@media (max-width: 1199px) {

  section,
  .section {
    scroll-margin-top: 66px;
  }
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
  text-align: center;
  padding-bottom: 60px;
  position: relative;

}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--accent-color);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 70vh;
  position: relative;
  padding: 0px 0 30px 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  

}

/* 
.hero .hero-bg img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
} */
/* 
.hero .hero-bg::before {
  content: "";
  background: rgba(var(--background-color-rgb), 0.85);
  position: absolute;
  inset: 0;
  z-index: 2;
} */

.hero .container {
  position: relative;
  z-index: 3;
}

.hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}

.hero h1 span {
  color: var(--accent-color);
}

.hero p {
  color: rgba(var(--default-color-rgb), 0.7);
  margin: 5px 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}


@media (min-width: 768px) {
  .hero .hero-img {
    max-width: 600px;
  }
}

/* .hero .btn-get-started {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 50px;
  transition: 0.5s;
  box-shadow: 0 8px 28px rgba(var(--accent-color-rgb), 0.2);
} */

/* .hero .btn-get-started:hover {
  color: var(--contrast-color);
  background: rgba(var(--accent-color-rgb), 0.85);
  box-shadow: 0 8px 28px rgba(var(--accent-color-rgb), 0.45);
} */

/* .hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  color: var(--default-color);
  font-weight: 600;
} */

/* .hero .btn-watch-video i {
  color: var(--accent-color);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
} */

.hero .btn-watch-video:hover {
  color: var(--accent-color);
}

.hero .btn-watch-video:hover i {
  color: rgba(var(--accent-color-rgb), 0.85);
}

@media (max-width: 640px) {
  .hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .hero p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .hero .btn-get-started,
  .hero .btn-watch-video {
    font-size: 13px;
  }


}

/*--------------------------------------------------------------
# Featured Services Section
--------------------------------------------------------------*/
.featured-services {
  --background-color: rgba(var(--accent-color-rgb), .06);
  padding: 40px 0;
}

.featured-services .service-item {
  position: relative;
  padding-top: 20px;
}

.featured-services .service-item .icon {
  background-color: rgba(var(--accent-color-rgb), 0.2);
  width: 72px;
  height: 72px;
  position: relative;
  margin-right: 15px;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
}

.featured-services .service-item .icon i {
  color: var(--accent-color);
  font-size: 32px;
  z-index: 2;
  position: relative;
}

.featured-services .service-item .title {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
}

.featured-services .service-item .title a {
  color: var(--heading-color);
}

.featured-services .service-item .title a:hover {
  color: var(--accent-color);
}

.featured-services .service-item .description {
  font-size: 14px;
}

.featured-services .service-item:hover .icon {
  background-color: var(--accent-color);
}

.featured-services .service-item:hover .icon i {
  color: var(--contrast-color);
}



.features-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #875088; /* Use a color similar to the one in your example */
  padding: 3rem;
  color: #ffffff;
  min-height: 60vh;
  font-family: 'Inter Tight', sans-serif;
 
}

.features-section h1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
  color: white;
}

/* Tablet responsiveness */
@media (max-width: 768px) {
  .features-section {
    padding: 2rem;
  }

  .features-section h1 {
    font-size: 2.5rem;
    line-height: 1.3;
  }
}

/* Mobile responsiveness */
@media (max-width: 480px) {
  .features-section {
    padding: 1.5rem;
  }

  .features-section h1 {
    font-size: 1.8rem;
    line-height: 1.3;
  }

  .logo-container {
    flex-direction: column;
    gap: 1rem;
  }
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about {
  padding-bottom: 20px;
  /* background: url(./img/bg1.jpg) no-repeat;
  background-size: cover; */
}

.about .content .who-we-are {
  text-transform: uppercase;
  margin-bottom: 15px;
  color: rgba(var(--default-color-rgb), 0.6);
}

.about .content h3 {
  font-size: 2rem;
  font-weight: 700;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 1.25rem;
  margin-right: 4px;
  color: var(--accent-color);
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .read-more {
  background: var(--accent-color);
  color: var(--contrast-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 12px 24px;
  border-radius: 50px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.about .content .read-more i {
  font-size: 18px;
  margin-left: 5px;
  line-height: 0;
  transition: 0.3s;
}

.about .content .read-more:hover {
  background: rgba(var(--accent-color-rgb), 0.8);
  padding-right: 19px;
}

.about .content .read-more:hover i {
  margin-left: 10px;
}

.about .about-images img {
  border-radius: 10px;
}

/*--------------------------------------------------------------
# Clients Section
--------------------------------------------------------------*/
.clients {
  padding: 20px 0;
}

.clients .client-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.clients .client-logo img {
  padding: 20px 40px;
  max-width: 90%;
  transition: 0.3s;
  opacity: 0.5;
  filter: grayscale(100);
}

.clients .client-logo img:hover {
  filter: none;
  opacity: 1;
}

@media (max-width: 640px) {
  .clients .client-logo img {
    padding: 20px;
  }
}

/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features .nav-tabs {
  border: 0;
}

.features .nav-item {
  width: 100%;
  margin-bottom: 15px;
}

.features .nav-item:last-child {
  margin-bottom: 0;
}

.features .nav-link {
  color: var(--heading-color);
  border: 0;
  padding: 30px;
  transition: 0.3s;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
}

.features .nav-link i {
  background-color: var(--contrast-color);
  color: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 48px;
  height: 48px;
  font-size: 22px;
  flex-shrink: 0;
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(var(--default-color-rgb), 0.15);
}

.features .nav-link h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
  transition: 0.3s;
}

.features .nav-link p {
  font-size: 15px;
  margin: 0;
}

.features .nav-link:hover {
  background: rgba(var(--accent-color-rgb), 0.04);
}

.features .nav-link.active {
  background: rgba(var(--accent-color-rgb), 0.08);
}

.features .tab-pane img {
  border-radius: 15px;

}


.project-section
{
  --background-color: rgba(var(--accent-color-rgb), .06);
}
/*--------------------------------------------------------------
# Features Details Section
--------------------------------------------------------------*/
.features-details .features-item+.features-item {
  margin-top: 30px;
}


.features-details {
  --background-color: rgba(var(--accent-color-rgb), .06);
}

.features-details .features-item .content {
  background-color: rgba(var(--accent-color-rgb), 0.05);
  color: rgba(var(--default-color-rgb), 0.6);
  padding: 30px;
  /* border-radius: 10px; */
}



.features-details .features-item h3 {
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 15px;
}

.features-details .features-item .more-btn {
  background-color: var(--accent-color);
  color: var(--contrast-color);
  padding: 6px 30px;
  /* border-radius: 6px; */
}

.features-details .features-item .more-btn:hover {
  background-color: rgba(var(--accent-color-rgb), 0.8);
}

.features-details .features-item ul {
  list-style: none;
  padding: 0;
}

.features-details .features-item ul li {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.features-details .features-item ul li:last-child {
  padding-bottom: 0;
}

.features-details .features-item ul i {
  font-size: 20px;
  margin-right: 10px;
  color: var(--accent-color);
}

.caption {
  text-align: left;
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
}

.caption h4 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.view-project-link {
  font-size: 1rem;
  color: #000;
  text-decoration: none;
  position: relative;
}

.view-project-link::after {
  content: '→';
  margin-left: 8px;
  color: #604f71;
  transition: margin-left 0.3s ease;
}

.view-project-link:hover::after {
  margin-left: 12px;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-lg-0 {
  margin-top: 0px !important;
}

.mt-5 {
  margin-top: 50px !important;
}

.mt-lg-9 {
  margin-top: 90px !important;
}

@media (max-width: 768px) {
  .mt-lg-9 {
    margin-top: 30px !important;
  }
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/


.services .service-item {
  background-color: var(--contrast-color);
  border: 1px solid rgba(var(--default-color-rgb), 0.15);
  height: 100%;
  padding: 30px;
  transition: 0.3s;
  border-radius: 10px;
  display: flex;
}

.services .service-item .icon {
  font-size: 32px;
  border-radius: 10px;
  position: relative;
  margin-right: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  flex-shrink: 0;
}

.services .service-item h3 {
  color: rgba(var(--heading-color-rgb), 0.75);
  font-weight: 700;
  font-size: 22px;
  transition: 0.3s;
}

.services .service-item p {
  margin-bottom: 0;
  color: rgba(var(--default-color-rgb), 0.6);
  transition: 0.3s;
}

.services .service-item .read-more {
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  transition: 0.3s;
  font-size: 14px;
}

.services .service-item .read-more i {
  margin-left: 10px;
}

.services .service-item.item-cyan .icon {
  color: #0dcaf0;
  border: 1px solid #0dcaf0;
  background: rgba(13, 202, 240, 0.1);
}

.services .service-item.item-orange .icon {
  color: #f7bea9;
  border: 1px solid #f7bea9;
  background: rgba(247, 190, 169, 0.1);
}

.services .service-item.item-teal .icon {
  color: #20c997;
  border: 1px solid #20c997;
  background: rgba(32, 201, 151, 0.1);
}

.services .service-item.item-red .icon {
  color: #df1529;
  border: 1px solid #df1529;
  background: rgba(223, 21, 4, 0.1);
}

.services .service-item.item-indigo .icon {
  color: #604f71;
  border: 1px solid #604f71;
  background: rgba(96, 79, 113, 0.1);
}

.services .service-item.item-pink .icon {
  color: #ef98a7;
  border: 1px solid #ef98a7;
  background: rgba(239, 152, 167, 0.2);
}

.services .service-item:hover {
  box-shadow: 0px 2px 25px rgba(var(--default-color-rgb), 0.1);
}

.services .service-item:hover h3 {
  color: var(--heading-color);
}

.services .service-item:hover p {
  color: rgba(var(--default-color-rgb), 0.9);
}

/*--------------------------------------------------------------
# More Features Section
--------------------------------------------------------------*/
.more-features .features-image {
  position: relative;
  min-height: 400px;
}

.more-features .features-image img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.more-features h3 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.more-features .icon-box {
  margin-top: 30px;
}

.more-features .icon-box i {
  color: var(--accent-color);
  margin-right: 15px;
  font-size: 24px;
  line-height: 1.2;
}

.more-features .icon-box h4 {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
}

.more-features .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
.pricing .pricing-item {
  background-color: rgba(var(--accent-color-rgb), 0.04);
  padding: 40px 40px;
  height: 100%;
  border-radius: 15px;
}

.pricing h3 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 20px;
}

.pricing h4 {
  color: var(--accent-color);
  font-size: 48px;
  font-weight: 700;
  font-family: var(--heading-font);
  margin-bottom: 0;
}

.pricing h4 sup {
  font-size: 28px;
}

.pricing h4 span {
  color: rgba(var(--default-color-rgb), 0.5);
  font-size: 18px;
  font-weight: 500;
}

.pricing .description {
  font-size: 14px;
}

.pricing .cta-btn {
  background-color: var(--default-color);
  color: var(--contrast-color);
  display: block;
  text-align: center;
  padding: 10px 35px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--heading-font);
  transition: 0.3s;
  margin-top: 20px;
  margin-bottom: 6px;
}

.pricing .cta-btn:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: rgba(var(--default-color-rgb), 0.7);
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.pricing ul li:last-child {
  padding-bottom: 0;
}

.pricing ul i {
  color: #059652;
  font-size: 24px;
  padding-right: 3px;
}

.pricing ul .na {
  color: rgba(var(--default-color-rgb), 0.4);
}

.pricing ul .na i {
  color: rgba(var(--default-color-rgb), 0.4);
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .featured {
  position: relative;
}

.pricing .featured .popular {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: var(--accent-color);
  color: var(--contrast-color);
  padding: 5px 15px;
  margin: 0;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
}

.pricing .featured .cta-btn {
  background: var(--accent-color);
  color: var(--contrast-color);
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}


.footer-section {
  background-color: rgba(128, 112, 148, 0.3);
  /* Purple background */
  color: white;
  padding: 175px 60px;
  text-align: left;
  height: 500px;
  /* margin: 50px auto; */
  justify-content: center;
  margin-bottom: -30px;
  margin-top: -30px;

}

.footer-section h1 {
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.learn {
  background-color: #604f71;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50px;
  transition: background-color 0.3s, color 0.3s;
}

.learn:hover {
  color: var(--contrast-color);
  background: rgba(var(--accent-color-rgb), 0.85);
  box-shadow: 0 8px 28px rgba(var(--accent-color-rgb), 0.45);
}

.contact-button {
  background-color: transparent;
  color: #604f71;
  border: 1px solid #604f71;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50px;
  transition: background-color 0.3s, color 0.3s;
}

.contact-button:hover {
  color: var(--contrast-color);
  background: rgba(var(--accent-color-rgb), 0.85);
  box-shadow: 0 8px 28px rgba(var(--accent-color-rgb), 0.45);
  border: none;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .buttons {
    flex-direction: column;
    align-items: center;
  }

  .learn,
  .contact-button {
    width: 100%;
    text-align: center;
  }
}


/*--------------------------------------------------------------
# Faq Section
--------------------------------------------------------------*/
.faq .section-title {
  padding-bottom: 20px;
}

.faq .faq-container .faq-item {
  position: relative;
  padding: 20px;
  margin-bottom: 15px;
  border: 1px solid rgba(var(--default-color-rgb), 0.15);
  border-radius: 5px;
  overflow: hidden;
}

.faq .faq-container .faq-item:last-child {
  margin-bottom: 0;
}

.faq .faq-container .faq-item h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0 30px 0 0;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.faq .faq-container .faq-item h3 .num {
  color: var(--accent-color);
  padding-right: 5px;
}

.faq .faq-container .faq-item h3:hover {
  color: var(--accent-color);
}

.faq .faq-container .faq-item .faq-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.faq .faq-container .faq-item .faq-content p {
  margin-bottom: 0;
  overflow: hidden;
}

.faq .faq-container .faq-item .faq-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  line-height: 0;
  transition: 0.3s;
  cursor: pointer;
}

.faq .faq-container .faq-item .faq-toggle:hover {
  color: var(--accent-color);
}

.faq .faq-container .faq-active {
  background-color: rgba(var(--accent-color-rgb), 0.03);
  border-color: rgba(var(--accent-color-rgb), 0.2);
}

.faq .faq-container .faq-active h3 {
  color: var(--accent-color);
}

.faq .faq-container .faq-active .faq-content {
  grid-template-rows: 1fr;
  visibility: visible;
  opacity: 1;
  padding-top: 10px;
}

.faq .faq-container .faq-active .faq-toggle {
  transform: rotate(90deg);
  color: var(--accent-color);
}

.faq {
  --background-color: rgba(var(--accent-color-rgb), .06);
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials {
  --background-color: rgba(var(--accent-color-rgb), .03);
}

.testimonials .testimonial-item {
  background-color: var(--contrast-color);
  box-shadow: 0px 0 20px rgba(var(--default-color-rgb), 0.1);
  box-sizing: content-box;
  padding: 30px;
  margin: 40px 30px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: 0.3s;
}

.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  border: 4px solid var(--background-color);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: rgba(var(--default-color-rgb), 0.6);
  margin: 0;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-wrapper {
  height: auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(var(--default-color-rgb), 0.15);
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

.testimonials .swiper-slide {
  opacity: 0.3;
}

@media (max-width: 1199px) {
  .testimonials .swiper-slide-active {
    opacity: 1;
  }

  .testimonials .swiper-pagination {
    margin-top: 0;
  }

  .testimonials .testimonial-item {
    margin: 40px 20px;
  }
}

@media (min-width: 1200px) {
  .testimonials .swiper-slide-next {
    opacity: 1;
    transform: scale(1.12);
  }
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item {
  padding: 20px 0 30px 0;
  box-shadow: 0 0 4px rgba(var(--default-color-rgb), 0.15);
}

.contact {
  --background-color: rgba(var(--accent-color-rgb), .03);

}

.contact .info-item i {
  color: var(--accent-color);
  width: 56px;
  height: 56px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  box-shadow: 0 2px 3px rgba(var(--default-color-rgb), 0.15);
}

.contact .info-item h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .php-email-form {
  height: 100%;
  padding: 30px;
  box-shadow: 0 0 4px rgba(var(--default-color-rgb), 0.15);
}

@media (max-width: 575px) {
  .contact .php-email-form {
    padding: 20px;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .contact .php-email-form {
    padding: 20px;
    height: 100%;
  }
}

.contact .php-email-form .error-message {
  display: none;
  background: #df1529;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: var(--background-color);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--accent-color);
  border-top-color: var(--background-color);
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 0;
  color: var(--default-color);
  background-color: rgba(var(--background-color-rgb), 0.5);
  border-color: rgba(var(--default-color-rgb), 0.2);
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--accent-color);
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
  color: rgba(var(--default-color-rgb), 0.3);
}

.contact .php-email-form button[type=submit] {
  color: var(--contrast-color);
  background: var(--accent-color);
  border: 0;
  padding: 10px 30px;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: rgba(var(--accent-color-rgb), 0.8);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Service Details Section
--------------------------------------------------------------*/
.service-details .service-box {
  padding: 20px;
  box-shadow: 0px 2px 20px rgba(var(--default-color-rgb), 0.12);
}

.service-details .service-box+.service-box {
  margin-top: 30px;
}

.service-details .service-box h4 {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid rgba(var(--default-color-rgb), 0.08);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.service-details .services-list a {
  color: rgba(var(--default-color-rgb), 0.8);
  background-color: rgba(var(--default-color-rgb), 0.04);
  display: flex;
  align-items: center;
  padding: 12px 15px;
  margin-top: 15px;
  transition: 0.3s;
}

.service-details .services-list a:first-child {
  margin-top: 0;
}

.service-details .services-list a i {
  font-size: 16px;
  margin-right: 8px;
  color: var(--accent-color);
}

.service-details .services-list a.active {
  color: var(--contrast-color);
  background-color: var(--accent-color);
}

.service-details .services-list a.active i {
  color: var(--contrast-color);
}

.service-details .services-list a:hover {
  background-color: rgba(var(--accent-color-rgb), 0.05);
  color: var(--accent-color);
}

.service-details .download-catalog a {
  color: var(--default-color);
  display: flex;
  align-items: center;
  padding: 10px 0;
  transition: 0.3s;
  border-top: 1px solid rgba(var(--default-color-rgb), 0.1);
}

.service-details .download-catalog a:first-child {
  border-top: 0;
  padding-top: 0;
}

.service-details .download-catalog a:last-child {
  padding-bottom: 0;
}

.service-details .download-catalog a i {
  font-size: 24px;
  margin-right: 8px;
  color: var(--accent-color);
}

.service-details .download-catalog a:hover {
  color: var(--accent-color);
}

.service-details .help-box {
  background-color: var(--accent-color);
  color: var(--contrast-color);
  margin-top: 30px;
  padding: 30px 15px;
}

.service-details .help-box .help-icon {
  font-size: 48px;
}

.service-details .help-box h4,
.service-details .help-box a {
  color: var(--contrast-color);
}

.service-details .services-img {
  margin-bottom: 20px;
}

.service-details h3 {
  font-size: 26px;
  font-weight: 700;
}

.service-details p {
  font-size: 15px;
}

.service-details ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.service-details ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.service-details ul i {
  font-size: 20px;
  margin-right: 8px;
  color: var(--accent-color);
}

/* project section */



/*--------------------------------------------------------------
# Starter Section Section
--------------------------------------------------------------*/
.starter-section {
  /* Add your styles here */
}


/* Add this to your CSS file */
.projects-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}

.projects-container .image-container,
.projects-container .text-container {
  width: 100%;
}

/* Apply styles for screens larger than 768px */
@media (min-width: 768px) {
  .projects-container {
    flex-wrap: nowrap;
  }
  .projects-container .image-container,
  .projects-container .text-container {
    width: 48%;
  }
  .projects-container:nth-child(even) .image-container {
    order: 2;
  }
  .projects-container:nth-child(even) .text-container {
    order: 1;
  }
}

/* Add this CSS in your stylesheet */
.scroll-to-top {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 2px solid darkgray;
  border-radius: 50%;
  color:#555;
  margin-left: 20px; /* Add some space between social links and the arrow */
  margin-top: 20px;
}

.scroll-to-top:hover {
  background-color: lightgrey;
}

/* Center the slider on mobile devices */
@media (max-width: 600px) {
  .technology-slide {
    display: flex !important;
    justify-content: center !important;
  }
}

.form-control-custom {
  /* border: 2px solid #604f71; */
  border-radius: 8px;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}

.form-control-custom:focus {
  border-color: #604f71;
  box-shadow: 0 8px 28px rgba(var(--accent-color-rgb), 0.45);
}

.form-check-custom .form-check-input:checked {
  background-color: #604f71;
  border-color: #604f71;
}

.form-check-custom .form-check-input {
  transition: all 0.3s ease-in-out;
}
.custom-modal .modal-header {
  position: relative;
}

.custom-modal .modal-header .btn-close-custom {
  position: absolute;
  /* top: 10px; */
  right: 20px;
  background: #604f71; /* Purple color for the background */
  border-radius: 50%; /* Circular border-radius */
  width: 30px; /* Width of the button */
  height: 30px; /* Height of the button */
  border: 2px solid #604f71; /* Border color same as background */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.custom-modal .modal-header .btn-close-custom svg {
  width: 18px; /* Width of the SVG icon */
  height: 18px; /* Height of the SVG icon */
}

.custom-swal-icon {
  color: #604f71 !important; /* Custom color for the icon */
}

.custom-swal-button {
  background-color: #604f71 !important; /* Custom color for the button */
  color: white !important; /* Text color */
}

.custom-swal-button:hover {
  background-color: #483855 !important; /* Darker shade for hover state */
}
.custom-swal-icon {
  color: #604f71 !important; /* Custom color for the icon */
}

.swal2-icon.swal2-info {
  border-color: #604f71 !important; /* Custom color for the circle */
}

.swal2-icon.swal2-info .swal2-icon-content {
  color: #604f71 !important; /* Custom color for the icon content */
}


.send-button
{
  color: var(--contrast-color);
  background: var(--accent-color);
  font-size: 14px;
  border-radius: 50px;
  transition: 0.3s;
  width: 30%;
  border: none;
}

.send-button:hover
{
  color: var(--contrast-color);
  background: var(--accent-color);
  font-size: 14px;
  border-radius: 50px;
  transition: 0.3s;
  width: 30%;
  border: none;
}
