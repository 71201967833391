.project-section {
  padding: 60px 0;
  
}

.main-image img {
  width: 100%;
  height: auto;
}

.section-title {
  text-align: center;
  margin-top: 40px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
}

.section-subtitle {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.feature-item {
  text-align: center;
  padding: 20px;
  border-left: 3px solid #ddd;
  border-right: 3px solid #ddd ;
  margin-bottom: 20px;
}

.feature-item img {
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
}

.feature-item h4 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 16px;
}

.divider {
  border-bottom: 3px solid #ddd;
  margin: 20px 0;
}

a{
  cursor: pointer;
}

@media screen and (max-width:464px) {
 .feature-item
 {
  border-right: none;
 }
}


